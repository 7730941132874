.photoshoot-pic {
  width: 100%;
}
.photoshoot-pic-wrapper:nth-child(2) {
  align-self: center;
}

.content-h1-title {
  display: flex;
  flex-direction: row;
  margin: 2em 0;
  align-items: center;
}
.content-h1-title i {
  margin-left: 5px;
  font-size: 1.5em;
}
.content-h1-title span {
  margin-left: 0.4em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: large;
  font-weight: 700;
}
.main-title {
  /* align-self: start; */
}
.content-h1-title h1 {
  margin: 0 10px;
  font-weight: 800;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: medium;
}
