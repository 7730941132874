.site-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  background-color: #000;
  margin-top: 100px;
}
.footer-wrap {
  display: flex;
  width: 100%;
  padding: 0;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.site-copyright {
  color: white;
  margin-right: 0;
}
.footer-social-icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 5px;
}
.footer-social-icon a {
  margin-left: 5px;
}
@media (min-width: 550px) {
  .footer-wrap {
    padding: 0 8em;
  }
  .site-copyright {
    margin-right: 2em;
  }
}

@media (min-width: 1000px) {
  .footer-wrap {
    padding: 0 8em;
  }
  .site-copyright {
    margin-right: 5em;
  }
}
