.main-navbar {
  display: flex;
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 999;
  width: 100%;
  height: 60px;
  padding: 5px 0px;
  background-color: rgb(0, 0, 0);
  color: white;
}
.navbar-bar {
  display: flex;
  /* width: calc(100% - 400px); */
  /* margin: 0 auto; */
  justify-content: space-between;
}
.brand {
  display: flex;
  order: 0;
  align-items: center;
}
.brand span {
  font-size: x-large;
  font-style: italic;
}
.brand-logo {
  width: 60px;
  margin: 0 10px;
}
.brand-title {
  display: none;
  flex-direction: column;
}

.nav-menu-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 0px 26px;
  /* margin: 0px 16px; */
}
.nav-menu {
  display: flex;
  margin-top: 20px;
}
.nav-menu li {
  margin: 0 15px;
}
.social-icon {
  display: none;
  align-items: center;
  flex-direction: row;
  margin-right: 5px;
}
.social-icon a {
  margin-left: 10px;
}
.test-img {
  width: 30px;
}
/* 
pink: #FFCDFE
yellow: #FFEDA3
blue: #9BE8FA



*/

@media (min-width: 450px) {
  .social-icon {
    display: flex;
  }
  .nav-menu-wrap {
    width: 50%;
  }
  .navbar-bar {
    margin: 0 auto;
    /* width: calc(100% - 400px); */
  }
}
@media (min-width: 510px) {
}
@media (min-width: 750px) {
  .navbar-bar {
    margin: 0 auto;
  }
  .brand-title {
    display: flex;
  }
  .social-icon {
    display: flex;
  }
  .brand-logo {
    margin: 0px;
  }
  .nav-menu-wrap {
    margin: 0px 16px;
  }
}

@media (min-width: 950px) {
  .navbar-bar {
    width: calc(100% - 400px);
  }
}
