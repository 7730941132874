.main-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  padding: 30px 0px 0px;
  /* height: 100vh; */
  /* background-color: #ececec; */
  background-color: #fff;
  box-shadow: 0px 18px 17px 3px #aaaaaa;
}
.header-home-sec {
  text-align: center;
  border-radius: 5px;
  margin-bottom: 2em;
  /* text-align: center;
  border-radius: 5px;
  margin-bottom: 5em; */
}
.header-home-sec-text {
  font-family: 'Calistoga', cursive;
  font-size: 1em;
  font-weight: 700;
  /* font-family: 'Bitter', serif; */
  background-color: #eee;
  text-align: center;
  padding: 5px 10px;
}

.home-hero-video {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}
.home-hero-video img {
  width: 90%;
  animation: fadeIn 3s;
}
/* video play */
.modal-btn {
  position: absolute;
  top: 45%;
  color: #fff;
  font-size: 4em;
}
.modal-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: xx-large;
}
.dialog-bg {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}
.dialog-center {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: calc(100% - 500px);
}
.dialog-center iframe {
  width: 840px;
  height: 473px;
}

/* 
-work section
*/
.work-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.work-section h2 {
  font-family: 'Bitter', serif;
  font-size: medium;
  margin: 2em 0;
}

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(auto, auto);
  grid-column-gap: 1em;
  grid-row-gap: 1em;
}
.video-wrap {
  display: flex;
  position: relative;
  margin: auto;
}
.video-vid {
  width: 100%;
  animation: fadeIn 3s;
}

.photoshot-sec {
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
    /* transform: translateY(0px); */
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    /* transform: translateY(-10px); */
  }
}
@media (min-width: 430px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 750px) {
  .main-content-container {
    width: calc(100% - 150px);
    margin: 70px auto 0;
    padding: 32px;
  }
  .home-hero-video img {
    width: 650px;
  }
  .header-home-sec {
  }
  .header-home-sec-text {
  }
}

@media (min-width: 1000px) {
  .video-vid {
    width: 350px;
  }
  .main-content-container {
    width: calc(100% - 500px);
    margin: 70px auto 0;
    padding: 32px;
  }
}
