/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  scroll-behavior: smooth;
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a {
  text-decoration: none;
  cursor: pointer;
}
a:focus {
  outline: 0;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: gray;
}

/* selected link */
a:active {
  color: #ececec;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type='search'] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.main-navbar {
  display: flex;
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 999;
  width: 100%;
  height: 60px;
  padding: 5px 0px;
  background-color: rgb(0, 0, 0);
  color: white;
}
.navbar-bar {
  display: flex;
  /* width: calc(100% - 400px); */
  /* margin: 0 auto; */
  justify-content: space-between;
}
.brand {
  display: flex;
  order: 0;
  align-items: center;
}
.brand span {
  font-size: x-large;
  font-style: italic;
}
.brand-logo {
  width: 60px;
  margin: 0 10px;
}
.brand-title {
  display: none;
  flex-direction: column;
}

.nav-menu-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 0px 26px;
  /* margin: 0px 16px; */
}
.nav-menu {
  display: flex;
  margin-top: 20px;
}
.nav-menu li {
  margin: 0 15px;
}
.social-icon {
  display: none;
  align-items: center;
  flex-direction: row;
  margin-right: 5px;
}
.social-icon a {
  margin-left: 10px;
}
.test-img {
  width: 30px;
}
/* 
pink: #FFCDFE
yellow: #FFEDA3
blue: #9BE8FA



*/

@media (min-width: 450px) {
  .social-icon {
    display: flex;
  }
  .nav-menu-wrap {
    width: 50%;
  }
  .navbar-bar {
    margin: 0 auto;
    /* width: calc(100% - 400px); */
  }
}
@media (min-width: 510px) {
}
@media (min-width: 750px) {
  .navbar-bar {
    margin: 0 auto;
  }
  .brand-title {
    display: flex;
  }
  .social-icon {
    display: flex;
  }
  .brand-logo {
    margin: 0px;
  }
  .nav-menu-wrap {
    margin: 0px 16px;
  }
}

@media (min-width: 950px) {
  .navbar-bar {
    width: calc(100% - 400px);
  }
}

.site-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  background-color: #000;
  margin-top: 100px;
}
.footer-wrap {
  display: flex;
  width: 100%;
  padding: 0;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.site-copyright {
  color: white;
  margin-right: 0;
}
.footer-social-icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 5px;
}
.footer-social-icon a {
  margin-left: 5px;
}
@media (min-width: 550px) {
  .footer-wrap {
    padding: 0 8em;
  }
  .site-copyright {
    margin-right: 2em;
  }
}

@media (min-width: 1000px) {
  .footer-wrap {
    padding: 0 8em;
  }
  .site-copyright {
    margin-right: 5em;
  }
}

.main-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  padding: 30px 0px 0px;
  /* height: 100vh; */
  /* background-color: #ececec; */
  background-color: #fff;
  box-shadow: 0px 18px 17px 3px #aaaaaa;
}
.header-home-sec {
  text-align: center;
  border-radius: 5px;
  margin-bottom: 2em;
  /* text-align: center;
  border-radius: 5px;
  margin-bottom: 5em; */
}
.header-home-sec-text {
  font-family: 'Calistoga', cursive;
  font-size: 1em;
  font-weight: 700;
  /* font-family: 'Bitter', serif; */
  background-color: #eee;
  text-align: center;
  padding: 5px 10px;
}

.home-hero-video {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}
.home-hero-video img {
  width: 90%;
  -webkit-animation: fadeIn 3s;
          animation: fadeIn 3s;
}
/* video play */
.modal-btn {
  position: absolute;
  top: 45%;
  color: #fff;
  font-size: 4em;
}
.modal-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: xx-large;
}
.dialog-bg {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}
.dialog-center {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: calc(100% - 500px);
}
.dialog-center iframe {
  width: 840px;
  height: 473px;
}

/* 
-work section
*/
.work-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.work-section h2 {
  font-family: 'Bitter', serif;
  font-size: medium;
  margin: 2em 0;
}

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(auto, auto);
  grid-column-gap: 1em;
  grid-row-gap: 1em;
}
.video-wrap {
  display: flex;
  position: relative;
  margin: auto;
}
.video-vid {
  width: 100%;
  -webkit-animation: fadeIn 3s;
          animation: fadeIn 3s;
}

.photoshot-sec {
  width: 100%;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    /* transform: translateY(0px); */
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    /* transform: translateY(-10px); */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    /* transform: translateY(0px); */
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    /* transform: translateY(-10px); */
  }
}
@media (min-width: 430px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 750px) {
  .main-content-container {
    width: calc(100% - 150px);
    margin: 70px auto 0;
    padding: 32px;
  }
  .home-hero-video img {
    width: 650px;
  }
  .header-home-sec {
  }
  .header-home-sec-text {
  }
}

@media (min-width: 1000px) {
  .video-vid {
    width: 350px;
  }
  .main-content-container {
    width: calc(100% - 500px);
    margin: 70px auto 0;
    padding: 32px;
  }
}

.about-sec {
  display: flex;
  flex-direction: column;
}
.about-info {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

.about-img-wrap {
}
.about-img {
  margin: 0 auto;
  display: flex;
  width: 100%;
}

.about-text {
  align-self: center;
  margin: 0 5px;
}
/* .about-info h1,
p {
  align-self: center;
  margin: 0 5px;
  /* width: 50%; */

.about-info h1 {
  font-family: 'Calistoga', cursive;
  font-size: x-large;
  font-weight: 800;
  margin-bottom: 1em;
}
.about-info p {
  font-family: 'Calistoga', cursive;
  line-break: loose;
  font-size: medium;
  color: black;
  line-height: 1.5;
}

@media (min-width: 410px) {
  .about-img {
    width: 80%;
  }
}
@media (min-width: 500px) {
  .about-img {
    width: 70%;
  }
}

@media (min-width: 700px) {
  .about-img {
    width: 50%;
    margin: 0;
  }
  .about-text {
    width: 50%;
    align-self: flex-end;
  }
}

.photoshoot-pic {
  width: 100%;
}
.photoshoot-pic-wrapper:nth-child(2) {
  align-self: center;
}

.content-h1-title {
  display: flex;
  flex-direction: row;
  margin: 2em 0;
  align-items: center;
}
.content-h1-title i {
  margin-left: 5px;
  font-size: 1.5em;
}
.content-h1-title span {
  margin-left: 0.4em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: large;
  font-weight: 700;
}
.main-title {
  /* align-self: start; */
}
.content-h1-title h1 {
  margin: 0 10px;
  font-weight: 800;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: medium;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */
.App {
  min-height: 100vh;
  background-image: url(/static/media/Simpleflix.dbc50766.jpg);
  /* background-image: linear-gradient(to bottom right, #ffcdfe, #ffeda3, #9be8fa); */
}

